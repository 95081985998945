<template>
  <div>
    <div class="pilotage-plan" v-if="!isMobile">
      <div class="lh-banner">
        <img src="../../assets/image/linghang/lh-banner.png" alt="">
        <div class="carousel-item-text">
          <div class="box-all">
            <div class="box-foot"></div>
          </div>
          <div class="font-family-AlimamaShuHeiTi white">领航计划</div>
          <div class="description white">聚焦中国优质国货的数字化出海服务，提供选品咨询，运营服务，市场开拓等服务</div>
        </div>
      </div>
      <div class="case">
        <div class="margin-t-100 big-title font-family-AlimamaShuHeiTi text-align-center font-60">“跨境电商+产业带”运营解决方案</div>
        <ul class="margin-t-100 font-20 black">
          <li>
            <div class="img-zoom"><img src="../../assets/image/linghang/fw.png" alt="" @mouseover="zoomIn" @mouseleave="zoomOut" class="zoomable-image"></div>
            <p class="margin-t-40">服务标准化</p>
          </li>
          <li>
            <div class="img-zoom"><img src="../../assets/image/linghang/cy.png" alt="" @mouseover="zoomIn" @mouseleave="zoomOut" class="zoomable-image"></div>
            <p class="margin-t-40">行业差异化</p>
          </li>
          <li>
            <div class="img-zoom"><img src="../../assets/image/linghang/gx.png" alt="" @mouseover="zoomIn" @mouseleave="zoomOut" class="zoomable-image"></div>
            <p class="margin-t-40">方案个性化</p>
          </li>
        </ul>
      </div>
      <div class="mini-banner">
        <img src="../../assets/image/linghang/mini-banner.png" alt="">
      </div>
      <div class="analyze">
        <div class="margin-t-100 big-title font-family-AlimamaShuHeiTi text-align-center font-60">多维度数据分析</div>
        <ul class="margin-t-100">
          <li>
            <img src="../../assets/image/linghang/fx.png" alt="">
          </li>
          <li class="padding-l-40">
            <h3 class="font-40 margin-t-40">跨境电商平台数据分析</h3>
            <div class="margin-t-40 font-20">
              <span>这里是文案里是文案里是文案里是文案里是文案里是文案里是文案里是文案这里是文案里是文案里是文案里</span>
            </div>
            <div class="margin-t-40">
              <div class="dot"></div>
              <div class="font-16 desc">
                <span>这里是文案里是文案里是文案里是文案里是文案里是文案里是文案里是文案这里是文案里是文案里是文案里</span>
              </div>
            </div>
            <div class="margin-t-20">
              <div class="dot"></div>
              <div class="font-16 desc">
                <span>这里是文案里是文案里是文案里是文案里是文案里是文案里是文案里是文案这里是文案里是文案里是文案里</span>
              </div>
            </div>
            <div class="margin-t-20 margin-b-100">
              <div class="dot"></div>
              <div class="font-16 desc">
                <span>这里是文案里是文案里是文案里是文案里是文案里是文案里是文案里是文案这里是文案里是文案里是文案里</span>
              </div>
            </div>
            <div class="btn"><el-button @click="into">进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
          </li>
        </ul>
      </div>
      <div class="operate">
        <div class="padding-t-100 big-title font-family-AlimamaShuHeiTi text-align-center font-60">运营服务矩阵</div>
        <div class="operate-img">
          <img src="../../assets/image/linghang/yy.png" alt="">
        </div>
      </div>
      <div class="survey" @click="open">
        <div class="box">
          <p class="name">企业名称：</p><el-input size="mini" class="input" v-model="name"></el-input>
        </div>
        <div class="box">
          <p class="name">手机号：</p><el-input size="mini" class="input" v-model="name"></el-input>
        </div>
        <el-button>填写问卷</el-button>
      </div>
    </div>
    <div v-else class="m-pilotage-plan">
      <div class="m-lh-banner">
        <img src="../../assets/image/linghang/lh-banner.png" alt="">
        <div class="m-carousel-item-text">
          <div class="m-box-all">
            <div class="m-box-foot"></div>
          </div>
          <div class="m-title white">领航计划</div>
          <div class="m-description white">聚焦中国优质国货的数字化出海服务，提供选品咨询，运营服务，市场开拓等服务</div>
        </div>
      </div>
      <div class="m-case text-align-center">
        <div class="m-case-title">“跨境电商+产业带”运营解决方案</div>
        <ul class="m-case-grid">
          <li>
            <img src="../../assets/image/linghang/fw.png" alt="">
            <p class="m-desc">服务标准化</p>
          </li>
          <li>
            <img src="../../assets/image/linghang/cy.png" alt="">
            <p class="m-desc">行业差异化</p>
          </li>
          <li>
            <img src="../../assets/image/linghang/gx.png" alt="">
            <p class="m-desc">方案个性化</p>
          </li>
        </ul>
        <div class="m-mini-banner">
          <img src="../../assets/mobile-image/mini-img.png" alt="">
        </div>
      </div>
      <div class="m-analyze text-align-center">
        <div class="m-title">多维度数据分析</div>
        <div class="m-img"><img src="../../assets/image/linghang/fx.png" alt=""></div>
        <div class="content-text">
          <div class="m-small-title">跨境电商平台数据分析</div>
          <div class="m-desc">这里是文案里是文案里是文案里是文案里是文案里是文案里是文案里是文案</div>
          <div class="box">
            <div class="m-dot"></div>
            <div class="m-text">这里是文案里是文案里是文案里是文案里是文案里是文案里是文案里是文案</div>
          </div>
          <div class="box">
            <div class="m-dot"></div>
            <div class="m-text">这里是文案里是文案里是文案里是文案里是文案里是文案里是文案里是文案</div>
          </div>
          <div class="box">
            <div class="m-dot"></div>
            <div class="m-text">这里是文案里是文案里是文案里是文案里是文案里是文案里是文案里是文案</div>
          </div>
        </div>
        <div class="m-xz-btn" @click="$router.push('/coming-soon')"><el-button>进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
      </div>
      <div class="m-operate text-align-center">
        <div class="m-operate-title">运营服务矩阵</div>
        <div class="m-operate-img">
          <img src="../../assets/image/linghang/yy.png" alt="">
        </div>
      </div>
    </div>
    <el-dialog
        class="survey-content"
        title="供应商调查问卷表"
        center
        top="5vh"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        @close="handleClose"
        width="80%">
        <span>
          <div class="content">
            <el-form :model="formData" ref="formData" class="demo-ruleForm" label-width="160px" style="margin-right: 30px" :rules="rules">
              <el-divider><div class="font-18">企业信息</div></el-divider>
              <el-row style="margin-top: 50px">
                <el-col :span="6">
                  <el-form-item label="企业名称：" prop="companyName" :rules="{required: true, trigger: 'blur', message: '请输入企业名称'}">
                    <el-input placeholder="请输入企业名称" clearable v-model="formData.companyName"></el-input>
                  </el-form-item>
                  <el-form-item label="传统外贸规模：" prop="foreignTradeTraditionPerson">
                  <el-select style="width: 100%" v-model="formData.foreignTradeTraditionPerson" placeholder="请选择" clearable>
                    <el-option
                        v-for="item in companyCtNumberOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                 <el-form-item label="内贸年销售额：" prop="domesticSales">
                  <el-select style="width: 100%" v-model="formData.domesticSales" placeholder="请选择" clearable>
                    <el-option
                        v-for="item in domesticTradeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
                <el-col :span="6">
                  <el-form-item label="企业代码：" prop="companyCode">
                    <el-input placeholder="请输入企业代码" clearable v-model="formData.companyCode"></el-input>
                  </el-form-item>
                  <el-form-item label="跨境电商规模：" prop="foreignTradeEPerson">
                    <el-select style="width: 100%" v-model="formData.foreignTradeEPerson" placeholder="请选择" clearable>
                      <el-option
                          v-for="item in companyCtNumberOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="外贸年销售额：" prop="foreignSales">
                    <el-select style="width: 100%" v-model="formData.foreignSales" placeholder="请选择" clearable>
                      <el-option
                          v-for="item in domesticTradeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="联系人：" prop="contactName" :rules="{required: true, trigger: 'blur', message: '请输入联系人'}">
                    <el-input placeholder="请输入联系人" clearable v-model="formData.contactName"></el-input>
                  </el-form-item>
                   <el-form-item label="渠道：" prop="channel">
                     <el-select style="width: 100%" v-model="formData.channel" placeholder="请选择" clearable>
                      <el-option
                        v-for="item in channelOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="海外社媒账号：" prop="foreignAccount">
                   <el-select @change="handleChangeNetworking" style="width: 100%" v-model="formData.foreignAccount" multiple collapse-tags clearable placeholder="请选择">
                      <el-option
                          v-for="item in networkingOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                   </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="手机号：" prop="contactTel">
                    <el-input placeholder="请输入手机号" clearable v-model="formData.contactTel"></el-input>
                  </el-form-item>
                  <el-form-item label="主销国家：" prop="majorSellingCountries">
                    <el-input placeholder="请输入主销国家" clearable v-model="formData.majorSellingCountries"></el-input>
                  </el-form-item>
                  <el-form-item label="已入驻平台：" prop="enteredPlatform">
                   <el-select @change="handleChange" style="width: 100%" v-model="formData.enteredPlatform" multiple collapse-tags clearable placeholder="请选择">
                      <el-option
                          v-for="item in companyDomesticPlatformOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                      </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="企业官网：" prop="companyUrl">
                    <el-input placeholder="请输入企业官网" clearable v-model="formData.companyUrl"></el-input>
                  </el-form-item>
                  <el-form-item label="海外独立站：" prop="foreignWeb">
                    <el-input placeholder="请输入海外独立站" clearable v-model="formData.foreignWeb"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="账号名：" prop="foreignAccountUrl" class="account" style="opacity: 0">
                    <el-input placeholder="请输入账号名" clearable v-model="formData.foreignAccountUrl"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="平台店铺地址：" prop="enteredPlatformUrl" class="shop" style="opacity: 0">
                    <el-input placeholder="请输入平台店铺地址" clearable v-model="formData.enteredPlatformUrl"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="营业执照：" prop="businessLicenseFileUrl" :rules="{required: true, trigger: 'blur', message: '请上传营业执照'}">
                    <el-upload ref="upload" class="upload-demo" action="string" :before-upload="beforeUpload" :on-remove="handleDelete"
                               :http-request="UploadOrder" list-type="picture" :file-list="fileList1">
                    <el-button :disabled="fileList1.length >= 1 && fileNum1 !== 0" size="small">+ 点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">支持pdf/jpg/jepg/png格式，大小不超过10MB</div>
                  </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider><div class="font-18">商品信息</div></el-divider>
              <el-row style="margin-top: 50px">
                <el-col :span="6">
                  <el-form-item label="商品名称：" prop="goodsName">
                    <el-input placeholder="请输入商品名称" clearable v-model="formData.goodsName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商品参数：" prop="goodsParameter" >
                    <el-input placeholder="请输入商品参数" clearable v-model="formData.goodsParameter"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商品材质：" prop="goodsTexture">
                    <el-input placeholder="请输入商品材质" clearable v-model="formData.goodsTexture"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商品特性：" prop="goodsCharacteristic">
                    <el-input placeholder="请输入商品特性" clearable v-model="formData.goodsCharacteristic"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="商品图片：" prop="goodsPic1">
                  <el-upload ref="uploads" class="upload-demo" action="string" multiple :before-upload="beforeUpload" :on-remove="handleDeleteMultiple"
                             :http-request="UploadOrderMultiple" list-type="picture" :file-list="fileList2">
                    <el-button :disabled="fileList2.length == 3 && fileNum2 == 3" size="small">+ 点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">支持pdf/jpg/jepg/png格式，大小不超过10MB</div>
                  </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
        </div>
        </span>
      <span slot="footer" class="dialog-footer">
          <el-button :loading="loading" class="submit-btn" @click="submit">提 交</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from  'axios'
import { isMobileDevice } from '../../utils/detectDevice';
import { baseUrl } from '../../utils/settings'
export default {
  name: "pilotagePlan",
  data() {
    return {
      isMobile: isMobileDevice(),
      loading: false,
      name: '',
      dialogVisible: false,
      formData: {
        companyName: undefined,
        foreignTradeTraditionPerson: undefined,
        domesticSales: undefined,
        companyCode: undefined,
        foreignTradeEPerson: undefined,
        foreignSales: undefined,
        contactName: undefined,
        channel: undefined,
        foreignAccount: [],
        contactTel: undefined,
        majorSellingCountries: undefined,
        enteredPlatform: [],
        companyUrl: undefined,
        foreignWeb: undefined,
        foreignAccountUrl: undefined,
        enteredPlatformUrl: undefined,
        businessLicenseFileUrl:undefined, /** 营业执照文件地址 */
        businessLicenseFileName: undefined, /** 营业执照文件名称 */
        goodsName: undefined,
        goodsParameter: undefined,
        goodsTexture: undefined,
        goodsCharacteristic: undefined,
        goodsPic1: undefined,
        goodsPic2: undefined,
        goodsPic3: undefined,
      },
      rules: {
        companyCode: [
          { required: true, trigger: 'blur', message: '请输入企业代码' },
          { pattern: /^[a-zA-Z0-9]*$/, message: '只允许输入英文和数字字符', trigger: 'blur' },
          { min: 6, message: '长度不能少于6位', trigger: 'blur' }
        ],
        contactTel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' },
        ]
      },
      companyCtNumberOptions: [ {value: '0-10人',label: '0-10人'},{value: '10-50人',label: '10-50人'},{value: '50-100人',label: '50-100人'},{value: '100人以上',label: '100人以上'}],
      domesticTradeOptions: [ {value: '0-10万',label: '0-10万'},{value: '10-50万',label: '10-50万'},{value: '50-100万',label: '50-100万'},{value: '100万以上',label: '100万以上'}, ],
      channelOptions:[ {value: '1',label: 'B2B'}, {value: '2',label: 'B2C'}, {value: '3',label: '两者都有'}],
      networkingOptions: [{value: 'ins', label: 'ins'}, {value: 'Facebook', label: 'Facebook'}, {value: 'Tiktok', label: 'Tiktok'}, {value: '其他', label: '其他'}],
      companyDomesticPlatformOptions: [
        {value: '天猫', label: '天猫'},
        {value: '淘宝', label: '淘宝'},
        {value: '抖音', label: '抖音'},
        {value: '拼多多', label: '拼多多'},
        {value: '亚马逊', label: '亚马逊'},
        {value: '亚马逊海外', label: '亚马逊海外'},
        {value: '沃尔玛', label: '沃尔玛'},
        {value: 'wayfair', label: 'wayfair'},
        {value: 'Tiktok', label: 'Tiktok'},
        {value: 'temu', label: 'temu'},
        {value: '其他', label: '其他'}
      ],
      headers: { Authorization: "Bearer "},
      fileList1: [],
      fileList2: [],
      limit1: 1,
      limit2: 3,
      fileNum1: 0,
      fileNum2: 0,
    }
  },
  methods: {
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10 // 这里做文件大小限制
      if (!isLt2M) {
        this.$message.warning(`上传文件大小不能超过 10 MB!`)
        return false
      }
      if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png' && file.type !== 'image/pdf') {
        this.$message.warning(`请上传pdf/jpg/jepg/png格式的图片`)
        return false
      }
      return isLt2M
    },
    // 单张上传
    async UploadOrder (file) {
      if (this.fileNum1 >= this.limit1) {
        this.$message.warning(`上传文件数量不能超过${this.limit1}！`)
        return false
      }
      this.fileNum1++
      const formData = new FormData()
      formData.append('file', file.file)
      const response = await axios.post(`${baseUrl}/web/webapi/upload`, formData)
      if(response.data.code == '200'){
        this.formData.businessLicenseFileUrl = response.data.url
        this.formData.businessLicenseFileName = response.data.originalFilename
        this.fileList1.push({ name: file.file.name,url: this.formData.businessLicenseFileUrl, ...response.data })
        console.log(this.fileList1, '单张上传--fileList1')
        this.$message.success('上传成功')
        this.$refs.formData.clearValidate('businessLicenseFileUrl');
      }else {
        console.log('error submit!!');
        this.$message.error(`提交失败-${response.data.msg}`)
        this.$refs.upload.clearFiles();
        this.fileNum1--
        return false
      }

    },
    // 删除文件
    handleDelete() {
      this.fileList1.splice(0, 1)
      this.fileNum1--
      delete this.formData.businessLicenseFileUrl
      delete this.formData.businessLicenseFileName
    },
    // 多张上传
    async UploadOrderMultiple(file) {
      if (this.fileNum2 >= this.limit2) {
        this.$message.warning(`上传文件数量不能超过${this.limit2}！`)
        return false
      }
      this.fileNum2++
      const formData = new FormData()
      formData.append('files', file.file)
      const response = await axios.post(`${baseUrl}/web/webapi/uploads`, formData)
      if(response.data.code == '200'){
        const newObj = {}
        newObj.originalFilenames = response.data.originalFilenames
        newObj.urls = response.data.urls
        this.fileList2.push({ name: response.data.originalFilenames,url: response.data.urls,...response.data })
        const arr = []
        this.fileList2.forEach(item => {
          arr.push({
            originalFilenames: item.originalFilenames,
            urls: item.urls
          })
        })
        if(arr.length == 1) {
          this.formData.goodsPic1 = JSON.stringify(arr[0])
        }else if(arr.length == 2){
          this.formData.goodsPic2 = JSON.stringify(arr[1])
        }else if(arr.length == 3) {
          this.formData.goodsPic3 = JSON.stringify(arr[2])
        }
        console.log(this.fileList2, arr,'多张上传列表--this.fileList2')
        this.$message.success('上传成功')
      }else {
        console.log('error submit!!');
        this.$message.error(`提交失败-${response.data.msg}`)
        this.$refs.uploads.clearFiles();
        this.fileNum2--
        return false
      }

    },
    handleDeleteMultiple(file, fileList) {
      console.log(file, fileList)
      this.fileList2 = [...fileList]
      this.fileNum2--
      const newArr = []
      this.fileList2.forEach(item => {
        newArr.push({
          originalFilenames: item.originalFilenames,
          urls: item.urls
        })
      })
      if(newArr.length == 1) {
        this.formData.goodsPic1 = JSON.stringify(newArr[0])
      }else if(newArr.length == 2){
        this.formData.goodsPic2 = JSON.stringify(newArr[1])
      }else if(newArr.length == 3) {
        this.formData.goodsPic3 = JSON.stringify(newArr[2])
      }
    },
    handleChangeNetworking(e){
      const el = document.querySelector('.account')
      e.length > 0 ? el.style.opacity = '1' :el.style.opacity = '0'
    },
    handleChange(e) {
      const el = document.querySelector('.shop')
      e.length > 0 ? el.style.opacity = '1' :el.style.opacity = '0'
    },
    into() {
      window.open('https://lhjh.crossea.com', '_blank');
    },
    goto() {
      let routeUrl =  this.$router.resolve({ path: './coming-soon'})
      window.open(routeUrl.href, '_blank');
    },
    open() {
      this.dialogVisible = true
    },
    handleClose() {
      // 清空表单和文件列表
      this.$refs.formData.resetFields();
      this.fileList1 = [];
      this.fileList2 = [];
      this.fileNum1 = 0
      this.fileNum2 = 0
    },
    async submit() {
      try {
        this.$refs['formData'].validate(async (valid) => {
          if(this.formData.businessLicenseFileUrl){
            this.$refs.formData.clearValidate('businessLicenseFileUrl');
          }
          if (valid) {
            this.loading = true
            this.formData.foreignAccount = this.formData.foreignAccount.join(',')
            this.formData.enteredPlatform = this.formData.enteredPlatform.join(',')
            const response = await axios.post(`${baseUrl}/web/webapi/submitLHWJss`, this.formData);
            console.log(this.formData,response.data, '666')
            if(response.data.status == '0'){
              this.$message({
                message: '提交成功',
                type: 'success',
                duration: 5000 // 时间单位为毫秒，这里设置为5秒
              });
              this.loading = false
              this.dialogVisible = false
              // window.location.reload()
            } else {
              console.log('error submit!!');
              this.$message.error('提交失败')
              this.loading = false
              return false;
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      }catch (error) {
        this.loading = false
        this.dialogVisible = false
        return false;
      }
    },
    zoomIn(event) {
      const image = event.target;
      image.classList.add('zoomed-in');
    },
    zoomOut(event) {
      const image = event.target;
      image.classList.remove('zoomed-in');
    },

  }
}
</script>

<style scoped lang="less">
.pilotage-plan {
  width: 100%;
  height: 100%;
  color: #11142C;
  .survey {
    width: 260px;
    height: 150px;
    background-color: rgba(16, 40, 103, 0.8);
    border-radius: 10px;
    position: fixed;
    right: 0;
    bottom: 10%;
    color: white;
    padding: 20px;
    cursor: pointer;
    .box {
      display: flex;
      margin-bottom: 10px;
      overflow: hidden;
      font-size: 12px;
      .name {
        width: 90px;
        padding-top: 5px;
      }
      ::v-deep .el-input__inner {
        background-color: rgba(255,255,255,.3);
        border: 1px solid rgba(255,255,255,.5);
      }
    }
    ::v-deep .el-button--small{
      padding: 9px 85px;
    }
    ::v-deep .el-button {
      display: flex;
      margin: 0 auto;
      font-size: 12px;
    }
    ::v-deep .el-button:focus, .el-button:hover {
      color: #666;
      border-color: #ffffff;
      background-color: #ffffff;
    }
  }
}
.survey-content {
  ::v-deep .el-dialog__header {
    padding-top: 35px;
  }
  ::v-deep .el-dialog__title {
    font-size: 26px;
  }
  ::v-deep .el-upload-dragger {
    width: 300px;
    height: 120px;
    display: inline-block;
  }
  ::v-deep .el-upload__tip {
    display: inline-block;
    margin-top: 0px;
    margin-left: 10px;
    color: #F56C6C;
  }
  ::v-deep .el-dialog__footer {
    padding-bottom: 50px;
  }
  .dialog-footer {
    ::v-deep .el-button {
      width: 168px;
      height: 46px;
      background-color: #102867;
      border-color: #102867;
      color: white;
      font-size: 20px;
    }
  }
  ::v-deep .el-divider--horizontal {
    margin-left: 15px;
  }
  ::v-deep .el-upload-list--picture .el-upload-list__item {
    padding-left: 50px!important;
    height: 55px!important;
  }
  ::v-deep .el-upload-list--picture .el-upload-list__item-thumbnail {
    width: 35px!important;
    height: 35px!important;
    margin-left: -40px!important;
  }
  ::v-deep .el-upload-list--picture .el-upload-list__item.is-success .el-upload-list__item-name{
    line-height: 35px!important;
  }
  ::v-deep .el-upload-list--picture .el-upload-list__item-status-label {
    background: #102867!important;
  }
}
.lh-banner {
  width: 100%;
  height: 960px;
  position: relative;
}
.lh-banner img {
  width: 100%;
  height: 960px;
  background-size: 100%;
}
.carousel-item-text {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  box-sizing: border-box;
  text-align: center;
}
.box-all{
  width: 200px;
  height: 180px;
  border-top: 8px solid #ffffff;
  border-bottom: 8px solid #ffffff;
  z-index: 20;
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
}
.box-all:before, .box-all:after{
  position:absolute;
  width: 0px;
  height: 15px;
  content: "";
  top: 0;
}
.box-all:before, .box-foot:before{
  border-left: 8px solid #ffffff;
  left: 0px;
}
.box-all:after,.box-foot:after{
  border-right: 8px solid #ffffff;
  right: 0;
}
.box-foot{
  position:absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.box-foot:before, .box-foot:after{
  position:absolute;
  width: 0px;
  height: 15px;
  content: "";
  bottom: 0;
}

.description {
  font-size: 30px;
  margin: 60px 0 70px 0;
}
.case {
  width: 1280px;
  height: 760px;
  margin: 0 auto;
}
.case ul {
  width: 1280px;
  height: 500px;
  text-align: center;
}
.case ul li {
  width: 380px;
  height: 500px;
  float: left;
}

.case ul li:nth-child(2){
  margin: 0 70px;
}
.case ul li>img{
  width: 100%;
  height: 100%;
}
.case ul li .img-zoom {
  width: 380px;
  height: 380px;
  border-radius: 5px;
  overflow: hidden;
}
.zoomable-image {
  transition: transform 0.3s ease;
}

.zoomable-image.zoomed-in {
  transform: scale(1.2);
}
.mini-banner {
  width: 100%;
  height: 200px;
}
.mini-banner img {
  width: 100%;
  height: 100%;
}
.analyze {
  width: 1280px;
  height: 960px;
  margin: 0 auto;
}
.analyze ul {
  width: 1280px;
  height: 620px;
}
.analyze ul li {
  float: left;
  width: 50%;
  height: 620px;
}
.analyze ul img {
  width: 640px;
  height: 620px;
  background-size: 100%;
}
.analyze ul h3 {
  color: #11142C;
}
.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #102867;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 25px;
}
.desc {
  width: 540px;
  height: 50px;
  display: inline-block;
  line-height: 26px;
}
.btn .el-button--small {
  padding: 13px 28px;
  color: #ffffff;
  font-size: 20px;
}
.btn .el-button {
  width: 168px;
  height: 48px;
  background-color: #102867;
  border-color: #102867;
}
.operate {
  width: 100%;
  height: 960px;
  margin: 0 auto;
  background-color: #FBFBFB;
}
.operate-img {
  width: 1600px;
  height: 650px;
  margin: 20px auto;
}
.operate-img img{
  width: 100%;
  height: 100%;
}
</style>
<style scoped lang="less">
.m-pilotage-plan {
  .m-lh-banner {
    width: 100vw;
    height: 5rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .m-carousel-item-text {
      position: absolute;
      top: 40%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      .m-title {
        font-size: .5rem;
        font-family: AlimamaShuHeiTi;
      }
      .m-box-all{
        width: 1.25rem;
        height: 1rem;
        border-top: .05rem solid #ffffff;
        border-bottom: .05rem solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -10%;
        left: 50%;
        transform: translateX(-50%);
      }
      .m-box-all:before, .m-box-all:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        top: 0;
      }
      .m-box-all:before, .m-box-foot:before{
        border-left: .05rem solid #ffffff;
        left: 0px;
      }
      .m-box-all:after,.m-box-foot:after{
        border-right: .05rem solid #ffffff;
        right: 0;
      }
      .m-box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .m-box-foot:before, .m-box-foot:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        bottom: 0;
      }
      .m-description {
        font-size: .24rem;
        margin-top: .5rem;
      }
    }

  }
  .m-case {
    width: 100vw;
    height: 6.6rem;
    margin-top: .5rem;
    .m-case-title {
      font: .55rem AlimamaShuHeiTi;
      font-weight: 700;
      color: #121212;
    }
    .m-case-grid {
      height: 3.5rem;
      margin: .5rem;
      display: flex;
      justify-content: space-between;
      li {
        width: 2.9rem;
        height: 2.9rem;
        border-radius: .2rem;
        float: left;
        img {
          width: 100%;
          height: 100%;
        }
        .m-desc {
          margin-top: .3rem;
          font-size: .3rem;
          color: #000000;
          font-weight: 600;
        }
      }
    }
    .m-mini-banner {
      width: 100vw;
      height: 1.5rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .m-analyze {
    width: 100vw;
    height: 11rem;
    margin-top: .5rem;
    .m-title {
      font: .55rem AlimamaShuHeiTi;
      font-weight: 700;
      color: #121212;
    }
    .m-img {
      width: 6.6rem;
      height: 6.4rem;
      margin: 0 auto;
    }
    .content-text {
      width: 100vw;
      .m-small-title {
        font-size: .35rem;
        color: #11142C;
        font-weight: 400;
        margin-bottom: .2rem;
      }
      .m-desc {
        color: #11142C;
        font-size: .22rem;
      }
      .box {
        width: 100vw;
        display: flex;
        justify-content: center;
        margin-top: .1rem;
      }
      .m-dot {
        width: .12rem;
        height: .12rem;
        border-radius: 50%;
        background-color: #102867;
        margin-right: .12rem;
        margin-top: .1rem;
        display: inline-block;
      }
      .m-text {
        display: inline-block;
        font-size: .2rem;
        color: #11142C;
      }
    }
    .m-xz-btn .el-button--small {
      padding: .2rem 0.33rem .19rem .33rem;
      color: #ffffff;
      font-size: 0.28rem;
      background-color: #102867;
      display: block;
      margin: .3rem auto 0 auto;
    }
  }
  .m-operate {
    width: 100vw;
    height: 6.5rem;
    background-color: #FBFBFB;
    padding-top: .5rem;
    .m-operate-title {
      font: .55rem AlimamaShuHeiTi;
      font-weight: 700;
      color: #121212;
      margin-bottom: .3rem;
    }
    .m-operate-img {
      width: 100%;
      height: 5rem;
    }
  }
}
</style>
